// @ts-check
import React from "react"
import styled from "@emotion/styled"

import { HeaderLogo } from "./icons/logos"
import colors, { foregroundColors } from "../styles/colors"
import { typographyComponents } from "./typography"
import Link from "./link"
// import LanguageSelector from "./languageselector"
import { HoverButton } from "./button"
import useScrollState from "../hooks/useScrollState"
import {
  borderRadius,
  DesktopMediaQuery,
  MobileMediaQuery,
} from "../styles/constants"
import { MobileNav } from "./mobileNav"
import HamburgerButton from "./hamburgerButton"
import DemoContext from "../contexts/demo"

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`

const squish = 20

/**
 * @typedef {object} MenuProps
 * @property {Boolean} hasScrolled
 * @property {import("../styles/colors").TColor} bg
 * @property {import("../styles/colors").TColor} headerColor
 */

const MenuBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 80px;
  background-color: ${(/** @type {MenuProps} */ p) =>
    p.hasScrolled ? colors.white : colors[p.bg]};
  transform: translateY(${p => (p.hasScrolled ? -squish : 0)}px);
  transition: transform 0.15s cubic-bezier(0.86, 0, 0.07, 1),
    background-color 0.1s;

  ${MobileMediaQuery} {
    height: 60px;
    transform: none;
  }
`

const MenuInnerWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 80px;
  max-width: 1240px;
  padding: 0 100px;
  color: ${(/** @type {MenuProps} */ p) =>
    p.hasScrolled ? colors.darkgrey : foregroundColors[p.bg]};
  transform: translateY(${p => (p.hasScrolled ? -squish / 2 : 0)}px);
  transition: transform 0.15s cubic-bezier(0.86, 0, 0.07, 1), color 0.1s;

  ${MobileMediaQuery} {
    padding: 0 24px;
    height: 60px;
    transform: translateY(0px);
  }
`

const MenuSide = styled.div`
  display: flex;
  align-items: center;
`
const MenuSideLeft = styled(MenuSide)`
  justify-content: flex-start;
`
const MenuSideRightDesktop = styled(MenuSide)`
  flex: 1;
  justify-content: flex-end;

  ${MobileMediaQuery} {
    display: none;
  }
`

const MenuSideRightMobile = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding: 0.7rem 0;

  ${DesktopMediaQuery} {
    display: none;
  }
`

const MenuTextLink = styled(typographyComponents.bodySmall)`
  font-size: 15px;
  line-height: 21px;
  color: inherit;
  text-decoration: none;
  margin: 0 14px;
  padding: 15px 0;
  cursor: ${p => (p.to ? "pointer" : "default")};

  color: ${p => (p.isActiveTab ? "#666" : "inherit")};

  &:hover {
    color: #666;
  }

  @media (max-width: 768px) {
    margin: 0 8px;
  }
`
const SubMenuDropdownWrapper = styled.div`
  min-width: 227px;
  padding-top: 0.7rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: none;
`

const SubMenuDropdownContainer = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.2rem 0;
  border-radius: ${borderRadius};
  box-shadow: 0px 5px 30px 4px rgba(0, 0, 0, 0.15);

  &:after {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 8px;
    margin-left: -8px;
    top: -5px;
    border-bottom-color: ${colors.white};
  }
`

const MenuItem = styled.div`
  position: relative;

  &:hover {
    ${SubMenuDropdownWrapper}:only-of-type {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }
`

const LogoLink = styled(Link)`
  display: flex;
  z-index: 1000120;
  position: relative;
  text-decoration: none;
  margin-right: 10px;
  color: ${(/** @type { MenuProps }}*/ p) =>
    colors[p.headerColor] || "inherit"};
`
const TryItFreeButton = styled(HoverButton)`
  font-weight: 600;
  font-size: 14px !important;
  margin-left: 14px;
  border-radius: 74px;
  width: 132px;
  line-height: 24px !important;
  align-items: initial;
`

const DemoButton = styled(TryItFreeButton)`
  background-color: transparent;
  color: ${p => colors[p.variant || "primary"]};
  border: 2px solid ${p => colors[p.variant || "primary"]};
  outline: none;
  line-height: 23px !important;

  &:hover {
    background-color: ${p => colors[p.variant || "primary"]};
    border-color: ${p => colors[p.variant || "primary"]};
    color: ${colors.white};
  }
`

const Separator = styled.hr`
  width: 90%;
  border: 0.5px solid ${colors.lightgrey};
  margin-top: 0;
  margin-bottom: 0;
`

const menuItems = [
  {
    activeTabKey: "product",
    name: "Product",
    subItems: [
      {
        linkTo: "/product/self-service/",
        name: "Self-service",
      },
      {
        linkTo: "/product/live-support/",
        name: "Live support",
      },
    ],
  },
  {
    activeTabKey: "why-mavenoid",
    linkTo: "/why-mavenoid/",
    name: "Why Mavenoid?",
  },
  {
    activeTabKey: "pricing",
    linkTo: "/pricing/",
    name: "Pricing",
  },
  {
    buttonsBefore: true,
    activeTabKey: "learn",
    name: "Learn",
    subItems: [
      {
        activeTab: "learn",
        linkTo: "/customers/",
        name: "Customer stories",
      },
      {
        linkTo: "https://blog.mavenoid.com",
        name: "Blog",
      },
      {
        linkTo: "/webinars/",
        name: "Webinars",
      },
      {
        linkTo: "https://help.mavenoid.com/",
        name: "Help center",
      },
      {
        linkTo: "/roi/",
        name: "ROI calculator",
      },
    ],
  },
  {
    linkTo: "https://app.mavenoid.com/login/",
    name: "Log in",
  },
]

/**
 * @param {Object} props
 * @param {import("../styles/colors").TColor} props.bg
 * @param {import("../styles/colors").TColor} props.headerColor
 * @param {React.ReactNode} [props.demoButton]
 * @param {String} [props.activeTab]
 */
const Header = ({
  bg,
  headerColor = "primary",
  demoButton,
  activeTab = "",
}) => {
  const { open: openDemo } = React.useContext(DemoContext)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false)
  const hasScrolled = useScrollState(80)

  return (
    <Wrapper>
      <MenuBackground bg={bg} hasScrolled={hasScrolled} />
      <MenuInnerWrapper bg={bg} hasScrolled={hasScrolled}>
        <MenuSideLeft>
          <LogoLink
            headerColor={headerColor}
            hasScrolled={hasScrolled}
            aria-label="Home"
            to="/"
          >
            <HeaderLogo />
          </LogoLink>
        </MenuSideLeft>
        <MenuSideRightMobile>
          {demoButton === undefined ? (
            <DemoButton
              onClick={openDemo}
              center
              variant={headerColor}
              size="s"
            >
              Get a demo
            </DemoButton>
          ) : (
            demoButton
          )}
          <HamburgerButton
            headerColor={headerColor}
            style={{
              width: "24px",
              height: "24px",
              marginLeft: "1.5rem",
              zIndex: 1000120,
            }}
            isOpen={isMobileMenuOpen}
            onClick={() => setIsMobileMenuOpen(p => !p)}
          />
          <MobileNav
            headerColor={headerColor}
            items={menuItems}
            demoButton={demoButton}
            open={isMobileMenuOpen}
            onClose={() => setIsMobileMenuOpen(false)}
          />
        </MenuSideRightMobile>
        <MenuSideRightDesktop>
          {menuItems.map((item, i) => (
            <MenuItem key={item.linkTo || i}>
              <MenuTextLink
                isActiveTab={activeTab === item.activeTabKey}
                key={item.linkTo}
                as={Link}
                to={item.linkTo}
              >
                {item.name}
              </MenuTextLink>
              {item.subItems && (
                <SubMenuDropdownWrapper>
                  <SubMenuDropdownContainer>
                    {item.subItems.map((subItem, i) => (
                      <React.Fragment key={subItem.linkTo}>
                        <MenuTextLink
                          key={subItem.linkTo}
                          as={Link}
                          to={subItem.linkTo}
                          style={{ color: colors.darkgrey }}
                        >
                          {subItem.name}
                        </MenuTextLink>
                        {i < item.subItems.length - 1 && <Separator />}
                      </React.Fragment>
                    ))}
                  </SubMenuDropdownContainer>
                </SubMenuDropdownWrapper>
              )}
            </MenuItem>
          ))}
          {demoButton === undefined ? (
            <DemoButton
              onClick={openDemo}
              center
              variant={headerColor}
              size="s"
            >
              Get a demo
            </DemoButton>
          ) : (
            demoButton
          )}
          {/* <LanguageSelector /> */}
        </MenuSideRightDesktop>
      </MenuInnerWrapper>
    </Wrapper>
  )
}

export default Header
